import HeroThree from "../Three/HeroThree"
import Experience from "./Experience"
import {motion} from "framer-motion"
import trifles from "../../Images/trifles.svg"
import dtu from "../../Images/dtu.svg"
import bu from "../../Images/bu.svg"
import iau from "../../Images/iau.svg"


function About() {
    return (
      <div id="about" className="h-screen w-screen  shrink-0  snap-center text-white space-y-8 relative flex flex-col text-center justify-center items-center  md:flex-col max-w-7xl px-10 mx-auto md:space-y-12" >
        <motion.h3 className=" uppercase tracking-[10px] font-bold text-lightPurple text-3xl md:text-4xl mb-4  "
          initial={{
            opacity :0
          }}
          transition={{
            duration:1,
          }}
          whileInView={{
            opacity:1,
          }}>About</motion.h3>
        <div className="flex flex-col space-x-0 space-y-5  md:space-y-0 md:space-x-32 md:flex-row">
          <div className="experiences flex flex-col space-y-1  md:space-y-5">
            <motion.h4  
            initial={{
              opacity :0,
              y:10
            }}
            transition={{
              duration:1,
            }}
            whileInView={{
              opacity:1,
              y:0
            }} className="text-4xl md:text-5xl font-allura text-slate-200/70 mb-5"><span className="text-lightPurple">Ex</span>periences</motion.h4>
            <Experience year="2021-2022" organization="Trifles Games" position="Game Developer" logo={trifles} dur={2}/>
          </div>

          <div className="education flex flex-col space-y-2 md:space-y-5">
            <motion.h4  
            initial={{
              opacity :0,
              y:10
            }}
            transition={{
              duration:1,
            }}
            whileInView={{
              opacity:1,
              y:0
            }}className="text-4xl md:text-5xl font-allura text-slate-200/70 mb-5"><span className="text-lightPurple">Ed</span>ucation</motion.h4>
            <Experience year="2017-2020" organization="Bogazici Unv." position="Geotechnical Eng.  MSc" logo={bu} dur={2.4}/>
            <Experience year="2018-2019" organization="Denmark T. Unv." position="Geotechnical Eng." logo={dtu} dur={2.8} note="as Exchange Student"/>
            <Experience year="2012-2016" organization="Istanbul Aydin Unv." position="Civil Eng." logo={iau} dur={3.2}/>
          </div>
        </div>
      </div>
      
    )
  }
  
  export default About
  
  
  