import React from 'react'
import appStoreImage from "../../Images/appstore.svg"
import googlePlayImage from "../../Images/googleplay.svg"
import linkImage1 from "../../Images/link.svg"
import GithubLogo from "../../Images/github.svg"
import {motion} from "framer-motion";

import csharp from "../../Images/csharp.svg"
import unity from "../../Images/unity.svg"
import csslogo from "../../Images/css.svg"
import jslogo from "../../Images/js.svg"
import reactlogo from "../../Images/reactlogo.svg"
import tailwindlogo from "../../Images/tailwindlogo.svg"
import android from "../../Images/android.svg"
import microsoftforms from "../../Images/microsoftforms.svg"
import applestore from "../../Images/applestore.svg"
import python from "../../Images/python.svg"
import django from "../../Images/django.svg"
import nginx from "../../Images/nginx.svg"
import redux from "../../Images/redux.svg"





function Work(props) {
    var tecs = "C#, Unity"
    var gameImage = "Games/Images/"+props.data.name.replaceAll(" ","")+".png";
    var linkImage = appStoreImage
    var tecLogoSize = "h-6 w-6"
    if(props.data.type == 5){
        tecs = "Python, Django, Nginx"
        gameImage = props.data.imageLink;
        linkImage = linkImage1
    } 
    if(props.data.type == 3){
        tecs = "JS, CSS, React, Tailwind"
        gameImage = props.data.imageLink;
        linkImage = linkImage1
    } 
    if(props.data.type == 4){
        tecs = "C#, Windows Forms"
        linkImage = GithubLogo;
        gameImage = props.data.imageLink;
    } 
    if(props.data.type == 1){
        linkImage = appStoreImage
    } 
    if(props.data.type == 2){
        linkImage = googlePlayImage
    } 
    
  return (
    <motion.div 
    // whileHover={{
    //     y: -20,
    //     transition: { duration: 0.5 },
    //   }}

    className='flex p-10 w-[90%] h-100 flex-col relative snap-center rounded-lg space-y-7 flex-shrink-0  bg-slate-400/10 md:w-[400px] md:h-[400px]'>
            <img src={gameImage} className={props.data.link !="https://seriousenglish.app"?"w-32 h-32 rounded-lg xl:w-[140px] xl:h-[140px]":"w-16 h-16  my-8 rounded-lg xl:w-[90px] xl:h-[90px]"} />
            <h4 className='text-md font-bold uppercase text-gray-200 md:text-xl'>{props.data.name}</h4>
            <p className='text-sm text-gray-400 md:text-xl'>{tecs}</p>
            <div className='absolute flex flex-row justify-end right-5 top-0'>
                <a href={props.data.link} target="_blank">
                    <img src={linkImage} className="w-10 h-10 rounded-lg animate-pulse"></img>
                </a>
            </div>
            <div className='flex flex-row justify-end space-x-2'>
                <img src={csharp} className={props.data.type !=1?"hidden":tecLogoSize}></img>
                <img src={csharp} className={props.data.type !=0?"hidden":tecLogoSize}></img>
                <img src={csharp} className={props.data.type !=4?"hidden":tecLogoSize}></img>
                <img src={csharp} className={props.data.type !=2?"hidden":tecLogoSize}></img>
                <img src={unity} className={props.data.type >2?"hidden":tecLogoSize}></img>
                <img src={jslogo} className={props.data.type !=3?"hidden":tecLogoSize}></img>
                <img src={csslogo} className={props.data.type !=3?"hidden":tecLogoSize}></img>
                <img src={reactlogo} className={props.data.type !=3?"hidden":tecLogoSize}></img>
                <img src={redux} className={props.data.name !="Serious English"?"hidden":tecLogoSize}></img>
                <img src={tailwindlogo} className={props.data.type !=3?"hidden":tecLogoSize}></img>
                <img src={microsoftforms} className={props.data.type !=4?"hidden":tecLogoSize}></img>
                <img src={android} className={props.data.type !=2?"hidden":tecLogoSize}></img>
                <img src={applestore} className={props.data.type !=1?"hidden":tecLogoSize}></img>
                <img src={applestore} className={props.data.type !=0?"hidden":tecLogoSize}></img>
                <img src={python} className={props.data.type !=5?"hidden":tecLogoSize}></img>
                <img src={django} className={props.data.type !=5?"hidden":tecLogoSize}></img>
                <img src={nginx} className={props.data.type !=5?"hidden":tecLogoSize}></img>

            </div>
    
    </motion.div>
  )
}

export default Work