import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
function App() {
  return (
    <div className="App bg-darkGreen h-screen select-none ">
      
      <Navbar />
      <Hero />

      </div>
  );
}

export default App;
