
function Skills() {
    return (
      <div  className="h-screen w-full text-white flex justify-center items-center" >Skills</div>
    )
  }
  
  export default Skills
  
  
  