import games from "../../Data/games.json";
import Work from "./Work";
import {motion} from "framer-motion";
import Logo from '../../Images/logo.svg';
import Se from '../../Images/se.svg';
import Feather3d from "../../Images/feather3d.svg";

function Works() {
  const gameList = games.games.map((game) =>
  <Work data={game} className="snap-center" /> 
);
    return (
      <div id="works" className=" h-screen md:w-[70%] w-full shrink-0 snap-center snap-x snap-mandatory pt-20 text-white flex flex-col  text-left md:flox-row max-w-full justify-evenly mx-auto items-center" >
      <motion.h3 className="  uppercase tracking-[10px] font-bold text-lightPurple text-4xl  "
      initial={{
        opacity :0
      }}
      transition={{
        duration:1,
      }}
      whileInView={{
        opacity:1,
      }}>
        Works</motion.h3>
        <div className="w-full  items-center flex pl-5 space-x-5 overflow-x-scroll py-10 snap-x snap-mandatory scrollbar scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-lightPurple">
        <Work data={{"name":"API - Serious English","type":5,"imageLink":Se,"link":"https://seriousenglish.app"}} className="snap-center"/> 
        <Work data={{"name":"Serious English","type":3,"imageLink":Se, "link":"https://seriousenglish.app"}} className="snap-center"/> 
        <Work data={{"name":"ahmetkeklik.dev","type":3,"imageLink":Logo}} className="snap-center"/> 
        <Work data={{"name":"Lightweight Gallery","type":4,"imageLink":Feather3d, "link":"https://github.com/keklique/Lightweight-Gallery"}} className="snap-center"/> 
          {gameList}
        </div>
        

      </div>
      
    )
  }
  
  export default Works
  
  
  