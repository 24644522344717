import React from 'react'
import {motion} from "framer-motion"

function Experience(props) {
  return (
    <motion.div 
    initial={{
        opacity :0,
        y:12 * props.dur * props.dur
      }}
      transition={{
        duration:props.dur* props.dur/7,
      }}
      whileInView={{
        opacity:1,
        y:0
      }}
    className='flex flex-row space-x-10 items-start font-alegraya-500 justify-start mb-5'>
        <p className=' text-md md:text-xl text-lightPurple/70 font-bold'> {props.year}</p>
        <div className='flex flex-col items-start '>
            <h5 className='text-md md:text-xl font-bold text-slate-100/80'>{props.position}</h5>
            <p className='flex flex-row py-0 text-sm md:text-md text-gray-400 pl-3 md:pl-5'><img src={props.logo} className="h-4 w-4 mr-1 mt-[1px] mb:h-6 mb:w-6  mb:mr-2" />{props.organization}</p>
            <p className='text-[11px] md:text-[12px] text-gray-400 pl-5 md:pl-10 text-regular'>{props.note}</p>
        </div>
    </motion.div>
  )
}

export default Experience