import React, { useState } from 'react';
import {FaBars,FaTimes,} from 'react-icons/fa';

import Logo from '../Images/logo.svg';
import { Link } from 'react-scroll';
import { Color } from 'three';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const [activediv,setActiveDiv] = useState("home");

  const GoToDiv = (divid) => {
    console.log(divid);
    setActiveDiv(divid);
    // document.getElementById(divid).scrollIntoView();
    
    
  }

  return (
    <div className='fixed w-screen h-[80px] max-w-2xlg flex justify-end  items-center px-6 bg-[#0a192f00] text-gray-500 z-10 '>
      <div>
        <a href="/"><img src={Logo} alt='' style={{ width: '100px' ,height: " 50px"}} /></a>
        
      </div>

      

      
    </div>
  );
};

export default Navbar;