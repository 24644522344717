
import Home from "./Hero/Home";
import About from "./Hero/About";
import Works from "./Hero/Works";
import Skills from "./Hero/Skills";
import Contact from "./Hero/Contact";

import HeroThree from "./Three/HeroThree";
function Hero() {
  return (
    <div className="w-full h-screen snap snap-y snap-mandatory bg-darkGreen  flex flex-col scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-lightPurple scrollbar-thin">

         {/* <HeroThree/>
         <section id="home" className="snap-start w-full h-screen shrink-0">
            <Home />
        </section>
        <section id="about" className="snap-center w-full h-screen shrink-0 ">
            <About />
        </section>
        <section id="works" className="snap-center w-full h-screen shrink-0">
            <Works />
        </section>
        {/* <section id="skills" className="snap-center w-full h-screen shrink-0">
            <Skills />
        </section> 
        <section id="contact" className="snap-center w-full h-screen shrink-0">
            <Contact />
        </section> */}
        <Home/>
        <About/>
        <Works/>
        <Contact/>
      </div>
  );
}


export default Hero;