import {motion} from "framer-motion";
import {PhoneIcon,MailIcon, MapPinIcon, EnvelopeIcon, LinkIcon} from "@heroicons/react/24/solid";
import {SocialIcon} from "react-social-icons";
import { useForm } from "react-hook-form";
import {isMobile} from 'react-device-detect';

function Contact() {
  const { register, handleSubmit } = useForm();
  const onSubmit = (formData) => {
    window.location.href = "mailto:ahmetkeklik@outlook.com?subject="+formData.subject+"&body=Hi, my name is "+formData.name+"."+formData.message+ " My e-mail is" + formData.email;
  };
  var phoneNumber = "";
  if(isMobile){phoneNumber ="tel:+905063758151"}
    return (
      <div id="contact" className="h-screen w-full snap-center  shrink-0 text-white flex flex-col space-y-10 text-center justify-center my-auto items-center pt-16 md:pt-10  px-10 md:h-full " >
        
        
        <motion.h3 className=" uppercase tracking-[10px] font-bold text-lightPurple text-4xl mb-4 md:mb-10  "
        initial={{
          opacity :0
        }}
        transition={{
          duration:1,
        }}
        whileInView={{
          opacity:1,
        }}>Contact</motion.h3>

        <div className="flex flex-col items-center space-y-10 space-x-0  px-10 md:flex-row md:space-y-0 md:space-x-14">
          <div className="space-y-1 md:space-y-8 font-ubuntu font-sans text-slate-300/80">
            <div className="flex items-center space-x-2 justify-center">
              <MapPinIcon className="text-lightPurple h-5 w-5 animate-pulse mb:w-7 mb:h-7"/>
              <p className="text-lg sm:text-xl ">Istanbul, Turkey</p>
            </div>
            <div className="flex items-center space-x-2 justify-center">
              <EnvelopeIcon className="text-lightPurple h-5 w-5 animate-pulse mb:w-7 mb:h-7"/>
              <p className="text-lg md:text-xl "><a href="mailto:ahmetkeklik@outlook.com">ahmetkeklik<span className="">@</span>outlook.com</a></p>
            </div>

            <div className="flex items-center space-x-2 justify-center">
              <SocialIcon url="https://www.linkedin.com/in/ahmetkeklik/" fgColor="#865C63" bgColor="transparent" className="text-lightPurple h-5 w-5 animate-pulse mb:w-7 mb:h-7"/>
              <p className="text-lg md:text-xl "><a href="https://www.linkedin.com/in/ahmetkeklik/">ahmetkeklik</a></p>
            </div>
            <div className="flex items-center space-x-2 justify-center ">
              <PhoneIcon className="text-lightPurple h-5 w-5 animate-pulse mb:w-7 mb:h-7"/>
              <p className="text-lg md:text-xl "><a href={ phoneNumber} >+90 (506) 375 81 51</a></p>
            </div>
          </div>
          <form className="flex flex-col space-y-2  mx-auto" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex space-x-0 space-y-2 flex-col md:flex-row md:space-y-0 md:space-x-2">
                <input className="textInput h-12 md:h-16" type="text" placeholder="Name" {...register("name")}/>
                <input className="textInput h-12 md:h-16" type="email" placeholder="E-Mail" {...register("email")}/>
              </div>
              <input className="textInput h-12 md:h-16" type="text" placeholder="Subject" {...register("subject")}/>
              <textarea className="textInput h-14 md:h-20" placeholder="Message" {...register("message")}/>
              <button type="submit" className="py-3 px-10 rounded-lg text-lg bg-darkPurple text-slate-300/80 font-bold uppercase font-alegraya">Submit</button>
            </form>
        </div>

          </div>
    )
  }
  
  export default Contact
  
  
  