import { motion } from "framer-motion"
import {BsGithub,BsLinkedin,BsInstagram} from "react-icons/bs"

function Home() {
  return (
    <div id="home" className=" w-full h-screen shrink-0 h-screen w-full pt-10 snap-start text-white space-y-10 relative flex flex-col text-center justify-center items-center  md:flex-row max-w-7xl px-10 mx-auto" >

          <motion.img src="Images/me.png" className=" md:mb-0 flex-shrink-0 max-w-[75%] rounded-full object-cover md:rounded-md md:w-[400px] md:h-[500px]  xl:w-[400px] xl:h-[500px]"
          initial={{
            x:-200,
            opacity :0
          }}
          transition={{
            duration:1.5,
          }}
          whileInView={{
            opacity:1,
            x:0
          }}
          />

          <motion.div className="space-y-10 px-0 md:px-10" 
          // initial={{
          //   x:+200,
          //   opacity :0
          // }}
          // transition={{
          //   duration:1.5,
          // }}
          // whileInView={{
          //   opacity:1,
          //   x:0
          // }}
          >
              <motion.h4 className="text-3xl font-ubuntu font-bold text-[#E7DEE0] md:text-6xl"
              initial={{
            y:+50,
            opacity :0
          }}
          transition={{
            duration:1.5,
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
              >AHMET KEKLİK </motion.h4>
              <motion.p className="text-sm font-ubuntu text-[#E7DEE0] md:text-lg"
              initial={{
                y:+20,
                opacity :0
              }}
              transition={{
                duration:1,
              }}
              whileInView={{
                opacity:1,
                y:0
              }}>
                I am developer based in Istanbul, Turkey.<br/>
                              </motion.p>
              <motion.div className="flex flex-row items-center justify-center space-x-10 pt-4"
              initial={{
                y:-50,
                opacity :0
              }}
              transition={{
                duration:1,
              }}
              whileInView={{
                opacity:1,
                y:0
              }}>
                <a href="https://github.com/keklique" target="_blank">
                  <BsGithub className="text-slate-300/70" size={30}/>
                </a>
                <a href="https://www.linkedin.com/in/ahmetkeklik/" target="_blank">
                <BsLinkedin className="text-slate-300/70" size={30}/>
                </a>
                <a href="https://www.instagram.com/ahmetkeklique" target="_blank">
                <BsInstagram className="text-slate-300/70" size={30}/>
                </a>
                
              </motion.div>

          </motion.div>
      </div>
      
      
  )
}

export default Home


